<template>
  <div class="fluid" id="approvalList">
    <div>
      <div class="approval-list-div">
        <v-card class="px-3 py-2">
          <v-row>
            <v-col
              cols="12"
              class="d-flex"
              style="
                position: relative;
                padding: 0 20px;
                text-align: center;
                margin-top: 20px;
              "
            >
              <h6 class="indigo--text" style="font-weight: bold; margin: auto">
                HIRARKI APPROVAL
              </h6>
            </v-col>
            <v-col cols="12">
              <v-toolbar
                class="approval-list-toolbar-1"
                flat
                style="height: 60px; border-radius: 10px 10px 0 0"
              >
                <div style="width: 140px" class="me-5">
                  <v-select
                    :disabled="selectedData.length === 0"
                    label="Action"
                    style="position: relative; top: 15px; font-size: 12px"
                    v-model="actionValue"
                    :items="[
                      { id: 0, name: '' },
                      { id: 1, name: 'Delete' }
                    ]"
                    item-text="name"
                    item-value="id"
                    outlined
                    return-id
                    dense
                    @change="action"
                  >
                  </v-select>
                </div>
                <v-dialog
                  v-if="
                    getUserProfile.level.find(({ id }) => id === '1') !==
                    undefined
                  "
                  v-model="dialog"
                  width="500"
                  persistent
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      elevation="1"
                      color="indigo"
                      class="indigo--text font-weight-bold me-5"
                      style="font-size: 12px"
                      @click="addData"
                      :disabled="loading"
                    >
                      Tambah Data
                    </v-btn>
                  </template>

                  <v-form
                    :disabled="loading"
                    ref="entryForm"
                    @submit.prevent="submit"
                    style="position: relative"
                  >
                    <v-card>
                      <v-card-title class="text-h5 grey lighten-2">
                        Tambah Data
                      </v-card-title>

                      <v-card-text class="d-flex justify-center align-center">
                        <v-row
                          cols="12"
                          style="padding: 0 10px; position: relative; top: 20px"
                          no-gutters
                        >
                          <v-col cols="12" style="padding: 0 5px">
                            <p
                              class="text-left"
                              style="
                                margin: 0;
                                font-size: 12px;
                                font-weight: bold;
                                color: rgba(0, 0, 0, 0.5);
                              "
                            >
                              Company
                            </p>
                            <v-autocomplete
                              :items="dropdown.company"
                              item-text="name"
                              item-value="id"
                              return-id
                              outlined
                              dense
                              style="margin: 0; height: 55px"
                              v-model="form.company_id"
                              @change="companyWatcher"
                              :rules="companyRules"
                              :disabled="isDisableCompanyDropdown"
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12" style="padding: 0 5px">
                            <p
                              class="text-left"
                              style="
                                margin: 0;
                                font-size: 12px;
                                font-weight: bold;
                                color: rgba(0, 0, 0, 0.5);
                              "
                            >
                              Department / Section
                            </p>
                            <v-autocomplete
                              :items="dropdown.department"
                              item-text="name"
                              item-value="id"
                              return-id
                              outlined
                              dense
                              style="margin: 0; height: 55px"
                              v-model="form.department_id"
                              :rules="departmentRules"
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12" style="padding: 0 5px">
                            <p
                              class="text-left"
                              style="
                                margin: 0;
                                font-size: 12px;
                                font-weight: bold;
                                color: rgba(0, 0, 0, 0.5);
                              "
                            >
                              Approver
                            </p>
                            <v-autocomplete
                              ref="autocomplete"
                              :items="dropdown.approverData"
                              item-text="name"
                              item-value="id"
                              return-id
                              outlined
                              dense
                              hide-details
                              hide-no-data
                              style="margin: 0; height: 55px"
                              clearable
                              v-model="form.approver_employee_id"
                              :rules="approverRules"
                              :search-input.sync="approverSearch"
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12" style="padding: 0 5px">
                            <p
                              class="text-left"
                              style="
                                margin: 0;
                                font-size: 12px;
                                font-weight: bold;
                                color: rgba(0, 0, 0, 0.5);
                              "
                            >
                              Karyawan
                            </p>
                            <v-autocomplete
                              ref="autocomplete"
                              v-model="form.employee"
                              :items="dropdown.employeeData"
                              :rules="employeeRules"
                              :search-input.sync="employeeSearch"
                              item-text="name"
                              item-value="id"
                              return-id
                              outlined
                              hide-details
                              hide-no-data
                              clearable
                              small-chips
                              deletable-chips
                              multiple
                              cache-items
                            ></v-autocomplete>
                          </v-col>
                        </v-row>
                      </v-card-text>

                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-btn
                          color="error"
                          text
                          outlined
                          @click="close"
                          :loading="loading"
                        >
                          Batal
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          type="submit"
                          text
                          outlined
                          :loading="loading"
                        >
                          Simpan
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-form>
                </v-dialog>
                <div class="approval-list-toolbar-div">
                  <div style="width: 170px">
                    <v-select
                      @change="companyWatcher2"
                      v-model="paramAPI.company_id"
                      :items="dropdown.company"
                      label="Company"
                      style="
                        margin-left: 10px;
                        position: relative;
                        top: 15px;
                        font-size: 12px;
                      "
                      item-text="name"
                      item-value="id"
                      outlined
                      return-id
                      dense
                      clearable
                      :disabled="isDisableCompanyDropdown"
                    ></v-select>
                  </div>
                  <div style="width: 170px">
                    <v-select
                      v-model="paramAPI.department_id"
                      :items="dropdown.department"
                      @change="departmentWatcher"
                      label="Department"
                      style="
                        position: relative;
                        top: 15px;
                        margin-left: 10px;
                        font-size: 12px;
                      "
                      item-text="name"
                      item-value="id"
                      outlined
                      return-id
                      dense
                      clearable
                    ></v-select>
                  </div>
                  <div style="width: 220px">
                    <v-text-field
                      v-model="paramAPI.keyword"
                      label="Cari Nama"
                      type="search"
                      outlined
                      dense
                      @keyup.enter="searchEnter"
                      style="position: relative; top: 15px; margin-left: 10px"
                    ></v-text-field>
                  </div>
                </div>
                <!-- <div
                  style="width: 300px; margin-left:auto;"
                  class="approval-list-toolbar-div"
                >
                  <v-autocomplete
                    label="- cari nama -"
                    :items="dropdown.employeeData"
                    item-text="name"
                    item-value="id"
                    return-id
                    outlined
                    hide-details
                    hide-no-data
                    dense
                    style="margin: 0;height: 55px;"
                    clearable
                    v-model="paramAPI.employee_id"
                    :search-input.sync="employeeSearch"
                  ></v-autocomplete>
                </div> -->
              </v-toolbar>
              <!-- <v-toolbar
                class="approval-list-toolbar-2"
                flat
                style="
                  height: 60px;
                  border-radius: 10px 10px 0 0;
                  border-bottom: 1px solid #e0e0e0;
                "
              >
                <v-autocomplete
                  label="- cari nama -"
                  :items="dropdown.employeeData"
                  item-text="name"
                  item-value="id"
                  return-id
                  outlined
                  hide-details
                  hide-no-data
                  dense
                  style="margin: 0;height: 55px;"
                  clearable
                  v-model="paramAPI.employee_id"
                  :search-input.sync="employeeSearch"
                ></v-autocomplete>
              </v-toolbar> -->
            </v-col>

            <v-col cols="12">
              <v-data-table
                mobile-breakpoint="0"
                fixed-header
                height="58vh"
                v-model="selectedData"
                :headers="headers"
                style="cursor: pointer"
                :items="result"
                :loading="loading"
                @click:row="rowClick"
                item-key="id"
                :show-select="
                  getUserProfile.level.find(({ id }) => id === '1') !==
                  undefined
                "
                :single-select="true"
                :options.sync="options"
                :footer-props="{
                  showFirstLastPage: true,
                  showCurrentPage: true,
                  itemsPerPageOptions: [10, 15, 100, 1000, 10000]
                }"
                :server-items-length="totalData"
                @update:page="updatePage"
                @update:items-per-page="updateItemPerPage"
              >
                <template v-slot:[`item.employee`]="{ item }">
                  <div
                    style="
                      font-size: 12px;
                      padding: 0;
                      display: flex;
                      flex-direction: row;
                    "
                  >
                    <div v-for="(data, index) in item.employee" :key="index">
                      <v-chip small color="default" label class="m-1">
                        {{
                          data.employee !== null ? data.employee.name : 'NULL'
                        }}
                      </v-chip>
                    </div>
                  </div>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'
import buildType from '../../../services/buildType'
// import XLSX from 'xlsx'
export default {
  name: 'hierarchyposition',
  data: () => ({
    dialog: false,
    hrsApi: buildType.apiURL('hrs'),
    eSanqua: buildType.apiURL('esanqua'),
    build: process.env.VUE_APP_BUILD_TYPE,
    paramAPI: {
      page: 1,
      keyword: '',
      offset: 0,
      limit: 10000,
      length: 0,
      // sortBy: 'employee',
      // sortType: 'asc',
      company_id: null,
      department_id: null,
      itemsPerPage: 10000,
      employee_id: ''
    },
    totalData: 0,

    options: {
      page: 1,
      itemsPerPage: 10000,
      sortDesc: []
    },
    actionValue: 0,
    headers: [
      {
        text: 'Approver',
        value: 'name',
        align: 'left',
        sortable: false,
        width: '20%'
      },
      {
        text: 'Karyawan',
        value: 'employee',
        align: 'left',
        sortable: false,
        width: '80%'
      }
    ],
    dropdown: {
      company: [],
      department: [],
      employee: []
    },
    isDisableCompanyDropdown: false,
    employeeSearch: null,
    approverSearch: null,
    select: null,
    select2: null,
    result: [],
    selectedData: [],
    loading: false,
    form: {
      act: 'batch_add',
      id: '',
      company_id: null,
      department_id: null,
      approver_employee_id: null,
      previous_approver_employee_id: null,
      employee: []
    },
    approverRules: [],
    employeeRules: [],
    skNoRules: [],
    skDateRules: [],
    departmentRules: [],
    positionRules: [],
    companyRules: []
  }),

  async mounted() {
    this.paramAPI.company_id = Number(
      this.getUserProfile.employee.company.plant_id
    )
    setTimeout(async () => {
      await this.getDataFromApi()
      this.setAction()
      this.initDropdown()
    }, 200)
  },
  computed: {
    ...mapGetters(['getUserProfile'])
  },
  watch: {
    employeeSearch(val) {
      if (val !== '') {
        val && val !== this.select && this.querySelections(val)
      } else {
        this.select = null
        this.dropdown.employeeData = []
      }
    },
    approverSearch(val) {
      if (val !== '') {
        val && val !== this.select2 && this.querySelections2(val)
      } else {
        this.select2 = null
        this.dropdown.approverData = []
      }
    }
  },
  methods: {
    ...mapActions(['dropdownPlant']),
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then((r) => {
            resolve(r)
          })
      })
    },
    async querySelections(v) {
      await axios
        .get(`${this.hrsApi}employee/dropdown?keyword=${v}`)
        .then((res) => {
          this.loading = false
          if (res.data.status_code === '00') {
            return (this.dropdown.employeeData = res.data.data)
          }
          return (this.dropdown.employeeData = [])
        })
        .catch((err) => {
          this.dropdown.employeeData = []
          return console.log(err)
        })
    },
    async querySelections2(v) {
      await axios
        .get(`${this.hrsApi}employee/dropdown?keyword=${v}`)
        .then((res) => {
          this.loading = false
          if (res.data.status_code === '00') {
            return (this.dropdown.approverData = res.data.data)
          }
          return (this.dropdown.approverData = [])
        })
        .catch((err) => {
          this.dropdown.approverData = []
          return console.log(err)
        })
    },
    close() {
      this.$refs.entryForm.reset()
      // this.$refs.autocomplete.cachedItems = []
      // this.$refs.autocomplete.computedItems = []
      this.form = {
        act: 'batch_add',
        id: '',
        employee: [],
        department: null,
        approver_employee_id: null,
        previous_approver_employee_id: null,
        company_id: null
      }
      // this.paramAPI.company_id = null
      this.actionValue = 0
      this.selectedData = []
      this.dialog = false
    },
    action(event) {
      switch (event) {
        case 1:
          if (this.selectedData.length < 2) {
            this.delete()
          } else {
            this.showMsgDialog(
              'warning',
              'Maaf, untuk saat ini Anda hanya dapat menghapus 1 data pada satu waktu',
              false
            )
            setTimeout(() => {
              this.selectedData = []
              this.actionValue = null
            }, 200)
          }
          break
      }
    },
    setAction() {
      this.actionValue = 0
      this.selectedData = []
    },

    async getDataFromApi() {
      this.loading = true
      this.result = []
      await this.initDataTable()
        .then((data) => {
          if (data.status_code === '00') {
            this.totalData = data.total_record
            this.result = data.data
          }
        })
        .catch((err) => {
          console.log(err)
          this.result = []
        })
      //   .then(data => {
      //   setTimeout(() => {
      //     this.result = data.data
      //     this.totalData = data.total_record
      //   }, 2000)
      // })
      this.loading = false
    },
    async initDataTable() {
      let url = `${this.hrsApi}approval_matrix_hierarchy/list?keyword=${
        this.paramAPI.keyword
      }&offset=${this.paramAPI.offset * this.paramAPI.limit}&limit=${
        this.paramAPI.limit
      }`

      if (this.paramAPI.company_id !== null) {
        url = url + `&company_id=${this.paramAPI.company_id}`
      }
      if (this.paramAPI.department_id !== null) {
        url = url + `&department_id=${this.paramAPI.department_id}`
      }
      // &company_id=${
      //   this.paramAPI.company_id !== null ? this.paramAPI.company_id : ''
      // }&department_id=${
      //   this.paramAPI.department_id !== null ? this.paramAPI.department_id : ''
      // }`
      return await new Promise((resolve) => {
        axios
          .get(url)
          .then((res) => {
            console.log(res)
            resolve(res.data)
          })
          .catch((err) => {
            console.log(err)
          })
      })
    },
    addData() {
      this.form.act = 'batch_add'
      setTimeout(() => {
        this.dialog = true
      }, 300)
    },

    submit() {
      this.companyRules = [(v) => !!v || 'Company is required']
      this.approverRules = [(v) => !!v || 'Approver is required']
      this.employeeRules = [
        (v) => (v && v.length > 0) || 'Employee is required min 1'
      ]
      this.departmentRules = [(v) => !!v || 'Department is required']

      const self = this
      setTimeout(function () {
        if (self.$refs.entryForm.validate()) {
          self.createNewForm()
        }
      })
    },
    createNewForm() {
      const form = {
        act: this.form.act,
        employee: this.form.employee,
        approver_employee_id: Number(this.form.approver_employee_id),
        department_id: this.form.department_id
      }
      if (this.form.act === 'update') {
        Object.assign(form, {
          id: this.form.id,
          previous_approver_employee_id: Number(
            this.form.previous_approver_employee_id
          )
        })
      }
      this.save(form)
    },
    async save(form) {
      this.loading = true
      await axios
        .post(`${this.hrsApi}approval_matrix_hierarchy/save`, form)
        .then(async (res) => {
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
            await this.getDataFromApi()
            this.close()
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
        })
    },
    async rowClick(pItem) {
      if (
        this.getUserProfile.level.find(({ id }) => id === '1') !== undefined
      ) {
        this.form.act = 'update'
        this.form.id = pItem.employee[0].id
        this.form.company_id = this.paramAPI.company_id
        this.dropdownDepartment()

        this.form.department_id = pItem.employee[0].department.id
        this.form.approver_employee_id =
          pItem.employee[0].approver_employee.slice(
            0,
            pItem.employee[0].approver_employee.lastIndexOf('|')
          )
        this.form.previous_approver_employee_id =
          pItem.employee[0].approver_employee.slice(
            0,
            pItem.employee[0].approver_employee.lastIndexOf('|')
          )
        for (let i = 0; i < pItem.employee.length; i++) {
          if (pItem.employee[i].employee !== null) {
            this.form.employee.push(pItem.employee[i].employee.id)
          }
        }
        await this.querySelections('')
        await this.querySelections2('')
        setTimeout(() => {
          this.dialog = true
        }, 300)
      }
    },
    async delete() {
      this.showMsgDialog(
        'question',
        'Are you sure, This data will be deleted permanently',
        true
      ).then(async (res) => {
        if (res.isConfirmed) {
          this.loading = true
          await axios
            .delete(
              `${this.hrsApi}approval_matrix_hierarchy/delete/${this.selectedData[0].id}`
            )
            .then(async (res) => {
              this.setAction()
              this.showMsgDialog('success', res.data.status_msg, 'false')
              await this.getDataFromApi()
            })
            .catch((err) => {
              console.log(err)
            })
          this.loading = false
        } else {
          this.actionValue = null
        }
      })
    },

    convertDate(raw) {
      if (raw !== null) {
        const date = new Date(raw)
        const local = date.toLocaleDateString()
        return local
      }
    },
    changeDate(raw) {
      const selectedDate = new Date(raw)
      const nextDate = new Date(
        new Date(raw).setDate(selectedDate.getDate() + 1)
      )
      this.dateFilter = nextDate.toISOString().substr(0, 10)
      return this.dateFilter
    },
    async initDropdown() {
      // this.dropdownPlant()
      this.getDropdownCompany()
      this.dropdownDepartment(this.paramAPI.company_id)
      // await this.dropdownPosition()
    },
    async getDropdownCompany() {
      const level = this.getUserProfile.level.find(
        ({ id }) => id === '1' || id === '39'
      )
      if (level !== undefined) {
        if (level.user_user_level !== null) {
          if (
            level.user_user_level.allowed_company === null ||
            level.user_user_level.allowed_company.length === 0
          ) {
            this.dropdownCompanyApi()
          } else {
            this.dropdown.company = level.user_user_level.allowed_company
            this.isDisableCompanyDropdown = false
          }
        } else {
          this.dropdownCompanyApi()
        }
      } else {
        this.dropdownCompanyApi()
      }
    },
    async dropdownCompanyApi() {
      if (Number(this.getUserProfile.employee.company.plant_id) !== 6) {
        this.isDisableCompanyDropdown = true
      } else {
        this.isDisableCompanyDropdown = false
      }
      axios
        .get(`${this.eSanqua}master/universal/plant/dropdown`)
        .then((res) => {
          this.dropdown.company = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    async companyWatcher(p) {
      this.form.company_id = p

      this.dropdownDepartment(p)
    },
    async companyWatcher2(p) {
      this.paramAPI.company_id = p

      this.dropdownDepartment(p)
      await this.getDataFromApi()
    },
    async departmentWatcher(p) {
      this.paramAPI.department_id = p
      await this.getDataFromApi()
    },
    async dropdownDepartment(p) {
      await axios
        .get(
          `${this.hrsApi}master/universal/department/dropdown?filter=[{"company_id":${p}}]`
        )
        .then((res) => {
          if (res.data.status_code === '00') {
            return (this.dropdown.department = res.data.data)
          }
          this.dropdown.section = []
          return (this.dropdown.department = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.dropdown.department = [])
        })
    },
    // async dropdownPosition() {
    //   await axios
    //     .get(`${this.hrsApi}master/universal/employeelevel/dropdown`)
    //     .then(res => {
    //       if (res.data.status_code === '00') {
    //         return (this.dropdown.position = res.data.data)
    //       }
    //       return (this.dropdown.position = [])
    //     })
    //     .catch(err => {
    //       console.log(err)
    //       return (this.dropdown.position = [])
    //     })
    // },

    async updatePage(p) {
      this.paramAPI.page = p
      this.paramAPI.offset = p - 1
      await this.getDataFromApi()
    },
    async updateItemPerPage(p) {
      this.paramAPI.limit = p
      this.paramAPI.offset = 0
      await this.getDataFromApi()
    },
    async searchEnter() {
      await this.getDataFromApi()
    }
  }
}
</script>
<style lang="scss">
#approvalList {
  position: relative;
  .approval-list-div {
    padding: 15px;
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .approval-list-toolbar-1 {
      .approval-list-toolbar-div {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
      }
    }
    .approval-list-toolbar-2 {
      display: none;
    }
  }
}

@media (max-width: 768px) {
  #approvalList {
    .approval-list-div {
      .approval-list-toolbar-1 {
        .approval-list-toolbar-div {
          display: none;
        }
      }
      .approval-list-toolbar-2 {
        display: block;
      }
    }
  }
}
</style>
